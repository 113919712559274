import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import {
  IconAlertTriangle, IconChartBar,
  IconCheckupList,
  IconDatabaseImport, IconHome, IconId,
  IconReport,
  IconReportAnalytics,
  IconTimelineEventPlus, IconTransactionDollar, IconUsers
} from '@tabler/icons-react';
import IconBuildings from '@/assets/custom/IconBuildings';
import VEEGILENZ from '../../../../../../assets/VEEGILENZ.svg';
import PortfolioDashboardContainers from '../../../../../containers/dashboards/portfolio';
import { DashboardConfig } from '@/main/containers/dashboards/portfolio/types';
import dnaConfig from '../../../../../../config/dna.config.json';

const dashboardConfig: DashboardConfig = {
  sections: [
    {
      id: 'entry',
      icon: IconDatabaseImport,
      type: 'compact',
      widgets: [
        {
          baseGroupId: 'economical_potential',
          displayInfo: {
            displayType: 'barChart',
            valueType: 'number'
          },
          criteria: {
            id: 'person_weasse_economical_potential',
            filterId: 'person_weasse_economical_potential',
            on: 'person1',
            type: 'segmentationCriteria'
          }
        }
      ],
      groups: [
        {
          subGroupId: 'persons',
          subGroups: [
            {
              augmented: true,
              subGroupId: 'persons.withCompanies'
            }
          ]
        },
        {
          subGroupId: 'companies'
        }
      ]
    },
    {
      id: 'weasse_segmentation',
      icon: IconChartBar,
      type: 'compact',
      widgets: [
        {
          baseGroupId: 'weasse_segments_initial',
          displayInfo: {
            displayType: 'barChart',
            valueType: 'number'
          },
          criteria:
            {
              id: 'person_weasseAugmentedSegmentation_initial',
              filterId: 'person_weasseAugmentedSegmentation_initial',
              on: 'person1',
              type: 'segmentationCriteria'
            }
        },
        {
          baseGroupId: 'weasse_segments_join_left',
          displayInfo: {
            displayType: 'barChart',
            valueType: 'number',
            label: 'segmentation.dashboard.sections.weasse_segmentation.widgets.weasseSegmentsJoinLeft.label'
          },
          criterias: [
            {
              id: 'person_weasseAugmentedSegmentation_joined',
              filterId: 'person_weasseAugmentedSegmentation_joined',
              on: 'person1',
              type: 'segmentationCriteria'
            },
            {
              id: 'person_weasseAugmentedSegmentation_left',
              filterId: 'person_weasseAugmentedSegmentation_left',
              on: 'person1',
              type: 'segmentationCriteria'
            }
          ]
        },
        {
          baseGroupId: 'weasse-segments-augmented',
          displayInfo: {
            displayType: 'barChart',
            valueType: 'number',
            label: 'segmentation.augmented.fullLabel'
          },
          criterias: [
            {
              id: 'person_weasseAugmentedSegmentation_initial',
              filterId: 'person_weasseAugmentedSegmentation_initial',
              on: 'person1',
              type: 'segmentationCriteria'
            },
            {
              id: 'person_weasseAugmentedSegmentation_joined',
              filterId: 'person_weasseAugmentedSegmentation_joined',
              on: 'person1',
              type: 'segmentationCriteria'
            },
            {
              id: 'person_weasseAugmentedSegmentation_left',
              filterId: 'person_weasseAugmentedSegmentation_left',
              on: 'person1',
              type: 'segmentationCriteria'
            }
          ],
          calculs: [
            {
              id: 'person_weasseAugmentedSegmentation',
              ids: ['person_weasseAugmentedSegmentation_initial', 'person_weasseAugmentedSegmentation_left'],
              operator: 'minus'
            }
          ]
        }
      ]
    },
    {
      id: 'indicators',
      icon: IconReportAnalytics,
      type: 'full',
      groups: [
        {
          subGroupId: 'persons.withCompanies',
          subGroups: [
            {
              subGroupId: 'persons.companies',
              icon: IconBuildings
            },
            {
              subGroupId: 'persons.network',
              subGroups: [
                {
                  subGroupId: 'persons.network.inBaseGroup'
                },
                {
                  subGroupId: 'persons.network.notInBaseGroup'
                },
                {
                  subGroupId: 'persons.network.personal',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.personal.client'
                    },
                    {
                      subGroupId: 'persons.network.personal.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.personal.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                },
                {
                  subGroupId: 'persons.network.business',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.business.client'
                    },
                    {
                      subGroupId: 'persons.network.business.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.business.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                },
                {
                  subGroupId: 'persons.network.extended',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.extended.client'
                    },
                    {
                      subGroupId: 'persons.network.extended.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.extended.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'companies'
        },
        {
          subGroupId: 'patrimonialComplexity',
          type: 'custom'
        }
      ]
    },
    {
      id: 'alerts',
      type: 'full',
      icon: IconAlertTriangle,
      groups: [
        {
          subGroupId: 'persons.veegilenz',
          type: 'segmentation',
          icon: IconUsers,
          img: VEEGILENZ,
          description: true,
          link: '../veegilenz',

          subGroups: [
            {
              subGroupId: 'persons.veegilenz.global',
              type: 'segmentation',
              icon: IconCheckupList,
              segmentationCriterias: [
                {
                  id: 'person_veegilenz_global',
                  filterId: 'person_veegilenz_global',
                  on: 'person1',
                  type: 'segmentationCriteria'
                }
              ]
            },
            {
              subGroupId: 'persons.veegilenz.nationality',
              type: 'segmentation',
              icon: IconId,
              segmentationCriterias: [
                {
                  id: 'person_veegilenz_facts_nationality',
                  filterId: 'person_veegilenz_facts_nationality',
                  on: 'person1',
                  type: 'segmentationCriteria'
                }
              ]
            },
            {
              subGroupId: 'persons.veegilenz.residence',
              type: 'segmentation',
              icon: IconHome,
              segmentationCriterias: [
                {
                  id: 'person_veegilenz_facts_residence',
                  filterId: 'person_veegilenz_facts_residence',
                  on: 'person1',
                  type: 'segmentationCriteria'
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'legalProcedures',
          type: 'select',
          icon: IconReport,
          options: ['1months', '3months', '6months', '12months'],
          subGroups: [
            {
              subGroupId: 'persons.withCompaniesWithAlerts'
            },
            {
              subGroupId: 'companies.withAlerts'
            }
          ]
        },
        {
          subGroupId: 'companiesCreated',
          type: 'select',
          icon: IconTimelineEventPlus,
          options: ['12months', '24months'],
          subGroups: [
            {
              subGroupId: 'persons.withRecentlyCreatedCompanies'
            },
            {
              subGroupId: 'companies.recentlyCreated'
            }
          ]
        },
        {
          subGroupId: 'transfer',
          type: 'select',
          icon: IconTransactionDollar,
          options: ['1months', '3months', '6months', '12months'],
          subGroups: [
            {
              subGroupId: 'persons.withCompaniesWithSoldCompanies'
            },
            {
              subGroupId: 'companies.withSoldCompanies'
            }
          ]
        }
      ]
    }
  ]
};

function DashboardTabsPortfolios() {
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();

  const callSegmentation = (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[] | string) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${entityType}&groupId=${groupId}${workflowId ? `&workflowId=${workflowId}` : ''}${filterIds ? `&filterIds=${filterIds.join(',')}` : ''}${filterValues ? `&filterValues=${Array.isArray(filterValues) ? filterValues.join(',') : filterValues}` : ''}`);
    }
  };

  return (
    <PortfolioDashboardContainers dashboardConfig={dashboardConfig} baseGroupId={data.data._id} callSegmentation={callSegmentation} />
  );
}

export default DashboardTabsPortfolios;
