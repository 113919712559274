import React from 'react';
import ReactMarkdown from 'react-markdown';
import Paper from '@mui/material/Paper';
import { ExecutiveSummaryInfo } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';

function ExecutiveSummarySummaries(props: { executiveSummaryData: ExecutiveSummaryInfo, selected: string }) {
  return (
    <Grid>
      <Paper sx={{ p: 4 }}>
        <ReactMarkdown>
          {props.executiveSummaryData.summaries.find(sum => sum.origin.model === props.selected)?.response}
        </ReactMarkdown>
      </Paper>
    </Grid>
  );
}

export default ExecutiveSummarySummaries;
