import { TFunction } from 'i18next';
import {
  APE_CODE_MAPPING,
  RNCS_FORME_JURIDIQUE_MAPPING,
  SegmentationFilterRange,
  SegmentationPossibleValueTypes
} from '@deecision/dna-interfaces';
import { currencyFormatter } from '@/utils/currencyFormatter';
import { getFlagEmoji } from '@/utils/getFlagEmoji';

export type ValuePossibleTypes = string | string[] | number | number[] | boolean | boolean[] | undefined | null | undefined[] | null[]; // | { value: string | number | boolean, range?: { min: number, max: number } } | { value: string | number | boolean, range?: { min: number, max: number } }[];

interface ValueParserOptions {
  value?: ValuePossibleTypes,
  range?: SegmentationFilterRange,
  valueType?: SegmentationPossibleValueTypes,
  currency?: string | null,
  t?: TFunction<'translation', undefined>
}

function getIfApeCode(value: string, t?: TFunction<'translation', undefined>) {
  const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

  if (regexCodeAPE.test(value)) {
    return `${APE_CODE_MAPPING[value as keyof typeof APE_CODE_MAPPING] || t?.('common.utils.unknown') || ''} (${value})`;
  }

  return value;
}

const getIfRncsCode = (value: string) => {
  if (value.toLowerCase().includes('rncs:')) {
    return `${RNCS_FORME_JURIDIQUE_MAPPING[value.split(':')[1] as keyof typeof RNCS_FORME_JURIDIQUE_MAPPING] || value.split(':')[1]} (${value})`;
  }

  return value;
};

function completenessFormatter(str: string) {
  const formattedCompleteness = parseFloat(str.substring(str.indexOf('-'), str.length).replace(/\s/g, '')) * 100;

  return `${formattedCompleteness.toFixed(0)}%`;
}

function format(value: ValuePossibleTypes, valueType?: SegmentationPossibleValueTypes, currency?: string | null, t?: TFunction<'translation', undefined>) {
  if (value === undefined || value === null || value?.toString() === t?.('common.utils.notFound')) return t?.('common.utils.notFound') || '';

  switch (valueType) {
  case undefined:
  case 'string':
  case 'number':
  case 'color':
  case 'veegilenzColor':
    return Array.isArray(value) ? value.map(v => (v ? getIfApeCode(getIfRncsCode(v.toString().replace('undefined', t?.('common.utils.notFound') || '')), t) : v?.toString() || t?.('common.utils.notFound') || '')).join(', ') : typeof value === 'string' ? getIfApeCode(getIfRncsCode(value.replace('undefined', t?.('common.utils.notFound') || '')), t) : value.toString();
  case 'boolean':
    return t ? t(value ? 'common.utils.yes' : 'common.utils.no') : value.toString();
  case 'amount':
    return Array.isArray(value) ? value.map(v => (currencyFormatter({ value: parseFloat(v?.toString() || '0'), currency }))).join(',') : currencyFormatter({ value: parseFloat(value?.toString() || '0'), currency }) || '0';
  case 'countryCode':
  case 'countryCode[]':
    return Array.isArray(value) ? value.map(v => getFlagEmoji(v?.toString())).join('') : getFlagEmoji(value.toString());
  case 'positionTypes':
    return Array.isArray(value) ? value.map(v => (v ? t?.(`common.positionTypes.${v?.toString()}`) || v?.toString() : t?.('common.utils.notFound') || '')).join(', ') : value ? t?.(`common.positionTypes.${value?.toString()}`) || value?.toString() : t?.('common.utils.notFound') || '';
  case 'asset_complexity_reasons':
    return t
      ? Array.isArray(value) ? value.length <= 0 ? t?.('common.utils.notFound') || '' : value.map(val => val?.toString().split(', ').map(v => t?.(`segmentation.render.segmentationCriterias.asset_complexity_reasons.${v}`) || v).join(', ') || val?.toString()).join(', ') : value?.toString().split(', ').map(v => t?.(`segmentation.render.segmentationCriterias.asset_complexity_reasons.${v}`) || v).join(', ') || value?.toString()
      : Array.isArray(value) ? value.map(val => val?.toString() || val).join(', ') : value?.toString();
  case 'tag':
    return t
      ? Array.isArray(value) ? value.length <= 0 ? t?.('common.utils.notFound') || '' : value.map(val => val?.toString().split(', ').map(v => t?.(`tags.values.${v}`) || v).join(', ') || val?.toString()).join(', ') : value?.toString().split(', ').map(v => t?.(`tags.values.${v}`) || v).join(', ') || value?.toString()
      : Array.isArray(value) ? value.map(val => val?.toString() || val).join(', ') : value?.toString();
  case 'completeness':
    return Array.isArray(value) ? value.map(v => v).join(',') : completenessFormatter(value.toString());
  case 'weasseEconomicalPotential':
    return Array.isArray(value) ? value.map(v => (t ? t(`common.levels.${v}`) : v?.toString())).join(',') : t ? t(`common.levels.${value}`) : value?.toString();
  default:
    return value?.toString();
  }
}

function valueParser({ value, range, valueType, currency, t }: ValueParserOptions) {
  if (value !== undefined && value !== null) {
    return format(value, valueType, currency, t);
  }

  if (range) {
    if (range.min === undefined || range.min === null) {
      return `< ${format(range.max, valueType, currency, t)}`;
    }

    if (range.max === undefined || range.max === null) {
      return `${format(range.min, valueType, currency, t)} <`;
    }

    return `${format(range.min, valueType, currency, t)} - ${format(range.max, valueType, currency, t)}`;
  }

  return t?.('common.utils.notFound') || '';
}

export default valueParser;
