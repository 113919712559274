import React, { useEffect, useMemo, useState } from 'react';
import { ExecutiveSummaryInfo, ExecutiveSummaryInfoStatus, ExecutiveSummaryProvider } from '@deecision/dna-interfaces';
import { useParams, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { Timeout } from 'react-number-format/types/types';
import ExecutiveSummaryHeader from '@/main/containers/executive-summary/header';
import { ExecutiveSummaryService } from '@/api/services/executiveSummary.services';
import ExecutiveSummarySummaries from '@/main/containers/executive-summary/summaries';
import ExecutiveSummaryNoSummaries from '@/main/containers/executive-summary/no-summaries';

interface ExecutiveSummaryProps {
  entityType: 'deecPerson' | 'deecCompany'
}

let intervalId: Timeout;

function ExecutiveSummary(props: ExecutiveSummaryProps) {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const executiveSummaryService = new ExecutiveSummaryService({ entityType: props.entityType });
  const [executiveSummaryData, setExecutiveSummaryData] = useState<ExecutiveSummaryInfo>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const selected = useMemo<string>(() => {
    const providerSelected: ExecutiveSummaryProvider | null = searchParams.get('provider') as ExecutiveSummaryProvider | null;

    return providerSelected ? executiveSummaryData?.summaries.find(sum => sum.origin.model === providerSelected)?.origin.model || '' : '';
  }, [searchParams.get('provider'), executiveSummaryData]);

  const setFirstProviderSelectedOnExecChange = (execSum: ExecutiveSummaryInfo) => {
    const firstAiProvider = execSum.summaries[0]?.origin.model;

    setSearchParams({ provider: firstAiProvider });
  };

  const fetchData = (entityId?: string) => {
    if (entityId) {
      executiveSummaryService.getExecutiveSummary(entityId)
        .then((res) => {
          if (res.data) {
            switch (res.data.status) {
            case ExecutiveSummaryInfoStatus.MISSING:
              if (refresh) {
                setExecutiveSummaryData({
                  ...res.data,
                  status: ExecutiveSummaryInfoStatus.PROCESSING
                });
              } else {
                setExecutiveSummaryData(res.data);
              }
              break;
            case ExecutiveSummaryInfoStatus.PROCESSING:
            case ExecutiveSummaryInfoStatus.PARTIAL:
              setExecutiveSummaryData(res.data);
              if (!refresh) {
                setRefresh(true);
              }
              break;
            case ExecutiveSummaryInfoStatus.COMPLETED:
              setExecutiveSummaryData(res.data);
              setFirstProviderSelectedOnExecChange(res.data);
              clearInterval(intervalId);
              setRefresh(false);
              break;
            default:
              setExecutiveSummaryData(res.data);
              clearInterval(intervalId);
              setRefresh(false);
              break;
            }
          }
        });
    }
  };

  useEffect(() => {
    if (props.entityType) {
      fetchData(id);
    }
  }, [props.entityType, id]);

  useEffect(() => {
    if (refresh) {
      console.log('test5');
      fetchData(id);
      intervalId = setInterval(fetchData, 3000, id);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [refresh]);

  const summaries = useMemo(() => {
    if (executiveSummaryData) {
      switch (executiveSummaryData?.status) {
      case ExecutiveSummaryInfoStatus.COMPLETED:
        return (
          <Grid container spacing={2}>
            <ExecutiveSummaryHeader
              executiveSummaryData={executiveSummaryData}
              executiveSummaryService={executiveSummaryService}
              selected={selected}
              setRefresh={setRefresh}
            />
            <ExecutiveSummarySummaries executiveSummaryData={executiveSummaryData} selected={selected}/>
          </Grid>
        );
      case ExecutiveSummaryInfoStatus.PARTIAL:
      case ExecutiveSummaryInfoStatus.PROCESSING:
      case ExecutiveSummaryInfoStatus.MISSING:
      case ExecutiveSummaryInfoStatus.FAILED:
      default:
        return <Stack alignItems='center' width='100%'>
          <ExecutiveSummaryNoSummaries
            executiveSummaryData={executiveSummaryData}
            executiveSummaryService={executiveSummaryService}
            selected={selected}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Stack>;
      }
    }

    return null;
  }, [executiveSummaryData, selected]);

  return (summaries);
}

export default ExecutiveSummary;
