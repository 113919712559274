import React, { ReactElement } from 'react';
import { useParams, Params, LoaderFunction } from 'react-router-dom';
import { Icon, IconProps } from '@tabler/icons-react';
import { BaseElementsService, CustomBaseService } from '@/api';
import { CustomRouteObject } from './types';
import loader, { customGetterLoader } from './loader';
import DataOutlet, { DataOutletProps } from '@/wrappers/dataoutlet';
import ServiceError from '@/api/services/error';
import Can from '@/auth/can';
import { ErrorPage } from '@/components/error';
import { WEBAPP_NAME } from '@/env/env';

interface CreateElementsRoutesProps<T, U = Record<string, never>, V = unknown> extends Omit<DataOutletProps, 'layout'> {
  id?: string,
  i18nKey?: string,
  slug?: string,
  idSlug?: string,
  slugSuffix?: true,
  path: string,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  sectionTitle?: string,
  component?: ReactElement,
  idComponent?: ReactElement,
  Service?: BaseElementsService<T>,
  AlternativeService?: Record<string | 'default', CustomBaseService<V>>,
  AlternativeServiceSelector?: string,
  IdService?: BaseElementsService<U>,
  AlternativeIdService?: Record<string | 'default', CustomBaseService<V>>,
  AlternativeIdServiceSelector?: string,
  displayIndex?: true,
  idGetAll?: true,
  forceGetId?: true
}

function DispatchTabComponents(props: { tabs?: CustomRouteObject[] }): ReactElement | null {
  const { tab } = useParams();
  const Component = props.tabs?.find(t => t.path === tab)?.element;
  const UnauthorizedPage = (
    <ErrorPage
      app={WEBAPP_NAME}
      errMessage='Unauthorized'
      errTitle='403'
    />
  );

  return (
    <Can
      asA={props.tabs?.find(t => t.path === tab)?.handle?.asA}
      fallback={UnauthorizedPage}
      passIfNoRole
    >
      {Component}
    </Can>
  );
}

function DispatchLoaderComponents(props: { params: Params<string>, tabs?: CustomRouteObject[], request: Request }): LoaderFunction['arguments'] | null {
  const tabLoader = props.tabs?.find(t => t.path === props.params.tab)?.loader as LoaderFunction;
  const Component = props.tabs?.find(t => t.path === props.params.tab)?.element;
  if (!Component) {
    throw new ServiceError(404, 'Not Found !');
  }

  if (!tabLoader) {
    return null;
  }

  return tabLoader({ params: props.params, request: props.request });
}

function createElementRoutes<T, U = object, V = unknown>(props: CreateElementsRoutesProps<T, U, V>): CustomRouteObject {
  return ({
    id: props.id,
    i18nKey: props.i18nKey,
    path: props.slug ? props.slugSuffix ? `${props.path}/:${props.slug}?` : `:${props.slug}?/${props.path}` : props.path,
    handle: {
      icon: props.icon,
      sectionTitle: props.sectionTitle,
      asA: props.asA
    },
    loader: (!props.idComponent && props.tabs) ?
      (props.Service ?
        ({ params, request }) => loader({
          service: props.Service,
          params,
          request,
          forceGetId: props.forceGetId
        }) :
        props.AlternativeService ? ({ params, request }) => customGetterLoader<V, CustomBaseService<V>>( {
          service: props.AlternativeService?.[new URL(request.url).searchParams.get(props.AlternativeServiceSelector || '') || ''] || props.AlternativeService?.default || undefined,
          params,
          request,
          forceGetId: props.forceGetId
        }) : undefined
      ) : undefined,
    element: (!props.idComponent && props.tabs) ? <DataOutlet layout={props.component} {...props} /> : undefined,
    children: [
      {
        index: true,
        loader: props.Service ?
          ({ params, request }) => loader({
            service: props.Service,
            params,
            request,
            forceGetId: props.forceGetId
          }) :
          props.AlternativeService ? ({ params, request }) => customGetterLoader<V, CustomBaseService<V>>( {
            service: props.AlternativeService?.[new URL(request.url).searchParams.get(props.AlternativeServiceSelector || '') || ''] || props.AlternativeService?.default || undefined,
            params,
            request,
            forceGetId: props.forceGetId
          }) : undefined,
        element: <Can
          {...props}
          fallback={
            <ErrorPage
              app={WEBAPP_NAME}
              errMessage='Unauthorized'
              errTitle='403'
            />
          }
          passIfNoRole
        >
          {props.component}
        </Can>
      },
      props.idComponent ? {
        path: props.idSlug ? `:${props.idSlug}?/:id` : ':id',
        loader: props.displayIndex ?
          props.IdService ?
            ({ params, request }) => loader<U>( {
              service: props.IdService,
              params,
              request,
              idGetAll: props.idGetAll
            }) :
            props.AlternativeIdService ? ({ params, request }) => customGetterLoader<V, CustomBaseService<V>>( {
              service: props.AlternativeIdService?.[new URL(request.url).searchParams.get(props.AlternativeIdServiceSelector || '') || ''] || props.AlternativeIdService?.default || undefined,
              params,
              request,
              idGetAll: props.idGetAll
            }) :
              props.Service ?
                ({ params, request }) => loader<T>( {
                  service: props.Service,
                  params,
                  request,
                  idGetAll: props.idGetAll
                }) :
                undefined
          : undefined,
        element: props.displayIndex ? <DataOutlet layout={props.idComponent} {...props} /> : undefined,
        children: props.displayIndex ?
          props.idChildrens && props.tabs ?
            [
              ...props.idChildrens,
              {
                path: ':tab',
                loader: ({ params, request }) => DispatchLoaderComponents({
                  tabs: props.tabs,
                  params,
                  request
                }),
                handle: {
                  tabs: props.tabs
                },
                element: <DispatchTabComponents {...props} />
              }
            ] :
            (props.idChildrens ?? (props.tabs ? [
              {
                path: ':tab',
                loader: ({ params, request }) => DispatchLoaderComponents({
                  tabs: props.tabs,
                  params,
                  request
                }),
                handle: {
                  tabs: props.tabs
                },
                element: <DispatchTabComponents {...props} />
              }
            ] : [])) :
          [
            {
              index: true,
              loader: props.IdService ?
                ({ params, request }) => loader<U>( {
                  service: props.IdService,
                  params,
                  request,
                  idGetAll: props.idGetAll
                })
                :
                props.AlternativeIdService ? ({ params, request }) => customGetterLoader<V, CustomBaseService<V>>( {
                  service: props.AlternativeIdService?.[new URL(request.url).searchParams.get(props.AlternativeIdServiceSelector || '') || ''] || props.AlternativeIdService?.default || undefined,
                  params,
                  request,
                  idGetAll: props.idGetAll
                }) :
                  props.Service ?
                    ({ params, request }) => loader<T>( {
                      service: props.Service,
                      params,
                      request,
                      idGetAll: props.idGetAll
                    }) : undefined,
              element: <DataOutlet layout={props.idComponent} {...props} />
            },
            ...(
              props.idChildrens ?? (props.tabs ? [
                {
                  path: ':tab',
                  loader: ({ params, request }: { params: Params<string>, request: Request }) => DispatchLoaderComponents({
                    tabs: props.tabs,
                    params,
                    request
                  }),
                  handle: {
                    tabs: props.tabs
                  },
                  element: <DispatchTabComponents {...props} />
                }
              ] : []))
          ]
      } :
        (!props.idComponent && props.tabs) ? {
          path: ':tab',
          loader: ({ params, request }) => DispatchLoaderComponents({
            tabs: props.tabs,
            params,
            request
          }),
          handle: {
            tabs: props.tabs
          },
          element: <DispatchTabComponents {...props} />
        } : {},
      ...(props.childrens || [])
    ]
  });
}

export default createElementRoutes;
