import React from 'react';
import Grid from '@mui/material/Grid2';
import { Button, Chip, Paper, Stack, Tooltip, useTheme } from '@mui/material';
import { IconRefresh } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ExecutiveSummaryInfo } from '@deecision/dna-interfaces';
import { upperFirst } from 'lodash';
import { ExecutiveSummaryService } from '@/api/services/executiveSummary.services';

interface ExecutiveSummaryHeaderProps {
  executiveSummaryData: ExecutiveSummaryInfo,
  executiveSummaryService: ExecutiveSummaryService,
  setRefresh: (status: boolean) => void,
  selected: string
}

function ExecutiveSummaryHeader(props: ExecutiveSummaryHeaderProps) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [, setSearchParams] = useSearchParams();

  const refreshExecutiveSummary = () => {
    if (id) {
      props.executiveSummaryService.refreshExecutiveSummary(id)
        .then(() => props.setRefresh(true));
    }
  };

  return (
    <Grid spacing={2} size={12} container display='flex' alignItems='center' pb={2} justifyContent='space-between'>
      <Grid id='execSumContext' direction='row' alignItems='center' container>
        <Grid direction='row' container>
          <Paper sx={{ p: 1 }}>
            <Stack direction='row' spacing={2} alignItems='center'>
              {Object.values(props.executiveSummaryData.summaries)
                .map(execSum => (
                  <Tooltip arrow placement='top' title={`Model Version: ${execSum?.origin.model}`}>
                    <Button
                      key={execSum?.origin.model}
                      variant='contained'
                      onClick={() => setSearchParams({ provider: execSum?.origin.model || '' })}
                      sx={{
                        borderRadius: '8px',
                        border: 'none',
                        bgcolor: props.selected === execSum?.origin.model ? theme.palette.background.paper : 'transparent',
                        color: theme.palette.text.primary,
                        '&:hover': {
                          boxShadow: 'none',
                          backgroundColor: theme.palette.background.paper
                        }
                      }}
                      size='small'
                    >
                      {upperFirst(`${execSum?.origin.provider} (${execSum?.origin.model})`)}
                    </Button>
                  </Tooltip>
                ))}
            </Stack>
          </Paper>
        </Grid>
        <Tooltip arrow placement='top' title={t('common.dates.lastUpdate')}>
          <Chip
            color='default'
            label={
              props.executiveSummaryData.summaries.find(sum => sum.origin.model === props.selected) ?
                new Date(props.executiveSummaryData.summaries.find(sum => sum.origin.model === props.selected)?.date || 0).toLocaleString(i18n.language.split('/')[0])
                :
                'N/A'
            }
            icon={<IconRefresh size='1rem'/>}
          />
        </Tooltip>
      </Grid>
      <Grid id='execSumActionButtons'>
        <Button
          variant='contained'
          onClick={refreshExecutiveSummary}
          startIcon={<IconRefresh size='1.2rem' />}
        >
          {t('executiveSummaries.actions.refresh')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ExecutiveSummaryHeader;
