import React, { ReactElement } from 'react';
import { useLoaderData } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import { IconBuildingBank, IconRefresh, IconUsers } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Tooltip, Chip } from '@mui/material';
import IconBuildings from '@/assets/custom/IconBuildings';
import ListCards from '../../../../components/cards/list.cards';
import dnaConfig from '../../../../config/dna.config.json';

function ComponentPortfolios(): ReactElement {
  const data = useLoaderData()  as { data : BaseGroup[] };
  const { t } = useTranslation();

  const rows = Array.isArray(data.data) ? data.data.map(dataGroup => ({
    id: dataGroup._id,
    name: dataGroup.label,
    icon: IconBuildingBank,
    color: 'primary' as const,
    link: `${dnaConfig.routes.portfolios.relativeUrl}/${dataGroup._id}`,
    components: [
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='flex-end'>
          <IconUsers size='1.6rem' />
          <Typography variant='h4'>{dataGroup.nbPersons}</Typography>
          <Typography>{dataGroup.nbPersons > 1 ? t('entities.persons.label') : t('entities.persons.person.label')}</Typography>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='flex-end'>
          <IconBuildings size='1.6rem' />
          <Typography variant='h4'>{dataGroup.nbCompanies}</Typography>
          <Typography>{dataGroup.nbCompanies > 1 ? t('entities.companies.label') : t('entities.companies.company.label')}</Typography>
        </Stack>
        <Stack>
          <Tooltip
            key='common.dates.lastUpdate'
            title={t('common.dates.lastUpdate')}
            arrow
            placement='top'
            sx={{ marginTop: '1rem' }}
          >
            <Chip
              label={dataGroup.valuesComputation?.lastEndDate ? new Date(dataGroup.valuesComputation.lastEndDate).toLocaleString() : '--'}
              icon={<IconRefresh size='1.4rem' />}
              size='medium'
            />
          </Tooltip>
        </Stack>
      </Stack>
    ]
  })) : [];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ListCards plusCard={{ link: dnaConfig.routes.portfolios.create.relativeUrl }} rows={rows} />
    </Box>);
}

export default ComponentPortfolios;
