import React, { ReactElement, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { TablerIcon } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material';

export interface TitleComponentProps {
  title: string | ReactNode,
  icon?: TablerIcon,
  chips?: ReactElement[],
  size?: 'large' | 'medium' | 'small',
  small?: true,
  disabled?: boolean
}

function TitleComponent(props: TitleComponentProps): ReactElement {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      width='100%'
      sx={{
        color: props.disabled ? 'grey.500' : undefined
      }}
      overflow='auto'
    >
      <Grid container spacing={2} wrap='nowrap'>
        <Grid display='flex' alignItems='center'>
          {props.icon &&
            <props.icon
              size={(props.small || props.size === 'small')
                ? '1.2rem'
                : props.size === 'medium'
                  ? '1.4rem'
                  : '1.8rem'
              }
              color={theme.palette.text.primary}
            />
          }
        </Grid>
        <Grid display='flex' alignItems='center'>
          {typeof props.title === 'string'
            ? <Typography
              variant={(props.small || props.size === 'small')
                ? 'h5'
                : props.size === 'medium'
                  ? 'h4'
                  : 'h3'
              }
              height='min-content'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
            >
              {props.title}
            </Typography>
            : props.title
          }
        </Grid>
      </Grid>
      {props.chips && props.chips.filter(chip => chip.key).map(chip => (
        <Grid key={chip.key} display='flex' alignItems='center'>
          {chip}
        </Grid>
      ))}
    </Grid>
  );
}

export default TitleComponent;
