import { ApiResponse } from '@deecision/deecision-interfaces';
import { DnaConfig } from '@deecision/dna-interfaces';
import axiosInstance, { BaseService } from '@/api';

export class ConfigService extends BaseService<DnaConfig> {
  constructor() {
    super({
      axiosInstance,
      elementPrefixUrl: 'config'
    });
  }

  getAll(props?: unknown): Promise<ApiResponse<DnaConfig>> {
    return this.axiosInstance.get(this.constructUrl(), { params: props })
      .then(res => this.handleResponse<DnaConfig>(res));
  }

  get(id: string, props?: unknown): Promise<ApiResponse<DnaConfig>> {
    return this.axiosInstance.get(this.constructUrl(id), { params: props })
      .then(res => this.handleResponse<DnaConfig>(res));
  }
}
