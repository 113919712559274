import React, { ReactElement, useEffect, useState } from 'react';
import { IDataElement } from '@deecision/dna-interfaces';
import { useParams, useLocation } from 'react-router-dom';
import { Chip, Grid2, IconButton, Stack, Tooltip } from '@mui/material';
import { IconJson, IconRepeatOnce, IconRefresh } from '@tabler/icons-react';
import { DataElementsQueryService } from '../../../../../../api/services/query';
import AdminCustomFilters from '../../listing/filters/adminCustomFilters';
import { AdminFilters } from '../../listing/filters/types';
import { Column, Row } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import JsonModal from '../../../recordSet/components/jsonModal';
import { dataInfoStatusColorMap, schedulingStatusColorMap } from '../utils/statusColorMapping';

function EntitiesDataElementTabs(): ReactElement {
  const { entityType } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const entityId = pathSegments[4];
  const dataElementServices = new DataElementsQueryService({ entityType });
  const [filters, setFilters] = useState<AdminFilters[]>([]);
  const [dataElements, setDataElements] = useState<(IDataElement & { _id: string })[]>([]);
  const [filteredDataElements, setFilteredDataElements] = useState<(IDataElement & { _id: string })[]>([]);
  const [jsonModalStates, setJsonModalStates] = useState<{open: boolean, data: (IDataElement & { _id: string }) | null}>({ open: false, data: null });
  const [refresh, setRefresh] = useState<boolean>(true);
  const sortByLastUpdate = (a: IDataElement, b: IDataElement) => new Date(b.dataInfo.lastUpdate).getTime() - new Date(a.dataInfo.lastUpdate).getTime();

  const refreshData = () => {
    setRefresh(true);
  };

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'dataInfoStatus', label: 'DataInfo Status' },
    { id: 'schedulingStatus', label: 'Scheduling Status' },
    { id: 'effectivePriority', label: 'Effective Priority' },
    { id: 'lastUpdate', label: 'Last Update' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = filteredDataElements
    .sort(sortByLastUpdate)
    .map(dataElement => ({
      id: dataElement.type,
      type: dataElement.type,
      dataInfoStatus: <Chip label={dataElement.dataInfo.status} color={dataInfoStatusColorMap[dataElement.dataInfo.status]} size='small'/>,
      schedulingStatus: <Chip label={dataElement.scheduling.status} color={schedulingStatusColorMap[dataElement.scheduling.status]} size='small'/>,
      effectivePriority: dataElement.scheduling.effectivePriority,
      lastUpdate: dataElement.dataInfo.lastUpdate,
      actions:
        <Stack direction='row'>
          <Tooltip arrow title='Restart DataElement'>
            <IconButton onClick={() => {
              dataElementServices.relaunchDataElements([dataElement.type], [dataElement.scheduling.status], dataElement._id)
                .then(refreshData);
            }}>
              <IconRepeatOnce color='blue'/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='See Json Object'>
            <IconButton onClick={() => {
              setJsonModalStates({ open: true, data: dataElement });
            }}>
              <IconJson color='grey'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }));

  const buildDataElementsFilters = (data: IDataElement[]) => {
    const filtersParsing: AdminFilters[] = [];
    filtersParsing.push({
      propertyKey: 'type',
      label: 'Type',
      type: 'autocomplete',
      values: [...new Set(data.map(dataElement => dataElement.type))]
    });
    filtersParsing.push({
      propertyKey: 'dataInfo.status',
      label: 'Status',
      type: 'multiselect',
      values: [...new Set(data.map(dataElement => dataElement.dataInfo.status))]
    });
    filtersParsing.push({
      propertyKey: 'scheduling.status',
      label: 'Scheduling Status',
      type: 'multiselect',
      values: [...new Set(data.map(dataElement => dataElement.scheduling.status))]
    });

    setFilters(filtersParsing);
  };

  const fetchDataElementsById = (id: string) => {
    setRefresh(false);
    dataElementServices.findByQuery({ entityId: id })
      .then((res) => {
        if (res.data) {
          const dataElementsResponse = Array.isArray(res.data) ? res.data as (IDataElement & { _id: string })[] : [];
          const dataSortedByEffectivePriority = dataElementsResponse.sort(a => a.scheduling.effectivePriority);
      
          setDataElements(dataSortedByEffectivePriority);
          setFilteredDataElements(dataSortedByEffectivePriority);
          buildDataElementsFilters(dataSortedByEffectivePriority);
        }
      });
  };

  const updateFilteredData = (filteredData: (IDataElement & { _id: string })[]) => {
    setFilteredDataElements(filteredData);
  };

  useEffect(() => {
    if (refresh) {
      fetchDataElementsById(entityId);
    }
  }, [entityId, refresh]);

  return (
    <>
      {entityType &&
        <Stack spacing={4}>
          <Grid2 direction='row' container>
            <Grid2 size='grow'>
              <AdminCustomFilters<(IDataElement & { _id: string })>
                entityType={entityType}
                referenceData={dataElements}
                updateData={updateFilteredData}
                filters={filters}
              />
            </Grid2>
            <Grid2 size='auto'>
              <Tooltip arrow title='Refresh Data' placement='top'>
                <IconButton title='Refresh' onClick={refreshData}>
                  <IconRefresh />
                </IconButton>
              </Tooltip>
            </Grid2>
          </Grid2>

          <Stack>
            <EvenOddTable<CurrencyFormatterProps>
              label='entities'
              columns={columns}
              rows={rows || []}
              totalCount={filteredDataElements.length}
              hideFooter
            />
            <JsonModal<(IDataElement & { _id: string })>
              jsonModalStates={jsonModalStates}
              setJsonModalStates={setJsonModalStates}
            />
          </Stack>
        </Stack>
      }
    </>
  );
}

export default EntitiesDataElementTabs;
