import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutiveSummaryInfo, ExecutiveSummaryInfoStatus } from '@deecision/dna-interfaces';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Box, Button, Chip } from '@mui/material';
import { IconActivity, IconPlayerPlay } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { ExecutiveSummaryService } from '@/api/services/executiveSummary.services';

const ExecSumStatusColorMapping: Record<ExecutiveSummaryInfoStatus, 'success' | 'error' | 'info' | 'warning' | 'default' | 'primary' | 'secondary'> = {
  [ExecutiveSummaryInfoStatus.COMPLETED]: 'success',
  [ExecutiveSummaryInfoStatus.MISSING]: 'error',
  [ExecutiveSummaryInfoStatus.PROCESSING]: 'info',
  [ExecutiveSummaryInfoStatus.PARTIAL]: 'warning',
  [ExecutiveSummaryInfoStatus.FAILED]: 'error'
};

interface ExecutiveSummaryNoSummariesProps {
  executiveSummaryData?: ExecutiveSummaryInfo,
  executiveSummaryService: ExecutiveSummaryService,
  selected: string,
  refresh: boolean,
  setRefresh: (status: boolean) => void
}

function ExecutiveSummaryNoSummaries(props: ExecutiveSummaryNoSummariesProps) {
  const { t } = useTranslation();
  const { id } = useParams();

  const generateExecutiveSummary = () => {
    if (id) {
      props.executiveSummaryService.generateExecutiveSummary(id)
        .then(() => props.setRefresh(true));
    }
  };

  const actions = useMemo(() => {
    switch (props.executiveSummaryData?.status) {
    case ExecutiveSummaryInfoStatus.PARTIAL:
    case ExecutiveSummaryInfoStatus.PROCESSING:
      return (
        <Stack spacing={2} alignItems='center' width='100%'>
          <Typography variant='body2'>
            {t(`executiveSummaries.${props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING}.action`)}
          </Typography>
          <Box width='100%' borderRadius={2} overflow='hidden'>
            <LinearProgress />
          </Box>
        </Stack>
      );
    case ExecutiveSummaryInfoStatus.COMPLETED:
      return null;
    case ExecutiveSummaryInfoStatus.MISSING:
    case ExecutiveSummaryInfoStatus.FAILED:
    default:
      return (
        <Button
          variant='contained'
          onClick={generateExecutiveSummary}
          startIcon={<IconPlayerPlay size='1.2rem' />}
        >
          {t(`executiveSummaries.${props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING}.action`)}
        </Button>
      );
    }
  }, [props.executiveSummaryData?.status]);

  return (
    <Paper sx={{ p: 4, pb: 8, maxWidth: 800 }}>
      <Stack spacing={6}>
        <Box>
          <Chip
            color={ExecSumStatusColorMapping[props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING]}
            label={t(`executiveSummaries.${props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING}.chip`)}
            icon={<IconActivity size='1rem'/>}
          />
        </Box>
        <Stack spacing={6} alignItems='center' pl={8} pr={8}>
          <Stack spacing={2} alignItems='center'>
            <Typography variant='h3'>
              {t(`executiveSummaries.${props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING}.title`)}
            </Typography>
            <Typography variant='body1' textAlign='center'>
              {t(`executiveSummaries.${props.executiveSummaryData?.status || ExecutiveSummaryInfoStatus.MISSING}.description`)}
            </Typography>
          </Stack>
          {actions}
        </Stack>
      </Stack>
    </Paper>
  );
}

export default ExecutiveSummaryNoSummaries;
