import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import { ExecutiveSummaryInfo } from '@deecision/dna-interfaces';
import BaseDnaEntitiesService, { BaseDnaEntitiesServiceProps } from '@/main/modules/entities/services/entities';

export class ExecutiveSummaryService extends BaseDnaEntitiesService<ExecutiveSummaryInfo> {
  constructor(params: BaseDnaEntitiesServiceProps) {
    super(params);
  };

  get(id?: string): Promise<ApiResponse<ExecutiveSummaryInfo>> {
    throw new Error(`Method not implemented. ${id}`);
  }

  getAll(): Promise<ApiArrayResponse<ExecutiveSummaryInfo>> {
    throw new Error(`Method not implemented.`);
  }

  getExecutiveSummary(entityId: string) {
    return this.axiosInstance.get(this.constructUrl(`${entityId}/executiveSummaries`))
      .then(res => this.handleResponse<ExecutiveSummaryInfo>(res));
  }

  generateExecutiveSummary(entityId: string) {
    return this.axiosInstance.post(this.constructUrl(`${entityId}/executiveSummaries`))
      .then(res => this.handleResponse<ExecutiveSummaryInfo>(res));
  }

  refreshExecutiveSummary(entityId: string) {

    return this.axiosInstance.put(this.constructUrl(`${entityId}/executiveSummaries/refresh`));
  }
}
