import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DnaConfig,
  SegmentationData, SegmentationPossibleDisplayTypes, SegmentationPossibleFilter
} from '@deecision/dna-interfaces';
import { undefined } from 'zod';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { WidgetSection } from './types';
import SegmentationServices, { CustomSegmentationFiltersService } from '../../segmentations/services';
import Dispatch from '@/main/containers/segmentations/render/charts/dispatch';
import { getBaseChartHeight } from '@/components/charts/utils';
import useWindowDimensions from '@/hooks/window-dimensions';
import MultipleDispatch from '@/main/containers/segmentations/render/charts/multiple-dispatch';
import { ConfigService } from '@/api/services/config';

function PortfolioDashboardWidgetSegmentation(props: WidgetSection): ReactElement {
  const { t } = useTranslation();
  const segmentationServices = new SegmentationServices();
  const possibleCriteriasService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const configService = new ConfigService();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const [segmentationConfigData, setSegmentationConfigData] = useState<DnaConfig>();
  const [displayInfo, setDisplayInfo] = useState<SegmentationPossibleDisplayTypes>(props.displayInfo?.displayType || 'barChart');
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [possibleCriterias, setPossibleCriterias] = useState<SegmentationPossibleFilter[]>();
  const possibleCriteria = possibleCriterias?.find(criteria => criteria.id === props.criteria?.filterId);

  const getCriterias = () => {
    possibleCriteriasService.getAll({ filters: [{ scope: 'on', id: 'on', value: 'person' }] })
      .then((res) => {
        setPossibleCriterias(res.data);
      });
  };

  const getSegmentationData =  () => {
    segmentationServices.perform({
      dataSetId: 'deecPersons',
      globalFilteringItems: [],
      filters: [
        {
          id: 'person_groupMember',
          filterId: 'person_groupMember',
          type: 'filter',
          on: 'person1',
          values: [
            `${id}/persons`
          ]
        }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: {}
      },
      outputEntities: 'person1',
      segmentationCriterias: props.criteria ? [props.criteria] : props.criterias || []
    })
      .then((res) => {
        let returnValue: SegmentationData = {
          ...res.data as SegmentationData
        };

        if (returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_left) {
          returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_left.values = returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_left.values?.filter(value => value.value !== null);
        }

        if (returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_joined) {
          returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_joined.values = returnValue.segmentationCriterias.person_weasseAugmentedSegmentation_joined.values?.filter(value => value.value !== null);
        }

        if (props.calculs) {
          props.calculs.forEach((calcul) => {
            calcul.ids.forEach((calculId) => {
              if (Object.keys(returnValue.segmentationCriterias).includes(calculId)) {
                returnValue = omit(returnValue, `segmentationCriterias.${calculId}`);
              }
            });
            returnValue = {
              ...returnValue,
              segmentationCriterias: {
                [calcul.id]: {
                  values: res.data?.segmentationCriterias?.[calcul.ids[0]]?.values
                    ?.map(((value) => {
                      const counts = calcul.ids
                        .map(calculId => res.data?.segmentationCriterias?.[calculId]?.values
                          ?.find(v => v.value === value.value)?.count || 0);

                      if (calcul.operator === 'minus') {
                        return {
                          ...value,
                          count: counts.slice(1)
                            .reduce((acc, curr) => acc - curr, counts[0])
                        };
                      }
                      if (calcul.operator === 'multiply') {
                        return {
                          ...value,
                          count: counts.slice(1)
                            .reduce((acc, curr) => acc * curr, counts[0])
                        };
                      }
                      if (calcul.operator === 'plus') {
                        return {
                          ...value,
                          count: counts.slice(1)
                            .reduce((acc, curr) => acc + curr, counts[0])
                        };
                      }
                      if (calcul.operator === 'divide') {
                        return {
                          ...value,
                          count: counts.slice(1)
                            .reduce((acc, curr) => acc / curr, counts[0])
                        };
                      }

                      return value;
                    })) || []
                },
                ...returnValue.segmentationCriterias
              }
            };
          });
        }

        setSegmentationData(returnValue);
      });
  };

  const criterias = useMemo(() => (
    [...(props.calculs?.map(calcul => ({ id: calcul.id, filterId: calcul.id, label: calcul.id })) || []), ...props.criterias || []]
      .filter(criteria => !props.calculs?.some(calcul => calcul.ids.includes(criteria.filterId || 'unknown')))
      ?.map(criteria => ({
        possibleDisplayTypes: possibleCriteria?.possibleDisplayTypes || [],
        on: possibleCriteria?.on || 'person',
        valueType: possibleCriteria?.valueType || 'number',
        values: criteria.filterId === 'person_weasseAugmentedSegmentation_left'
          ? segmentationData?.segmentationCriterias[criteria.id].values?.map(value => ({ ...value, count: (value.count || 0) * (-1) }))
          : segmentationData?.segmentationCriterias[criteria.id].values,
        isMultipleValuesOrRangesEnabled: true,
        id: criteria.id || 'unknown',
        filterId: criteria.filterId || 'unknown',
        label: possibleCriterias?.find(pc => pc.id === criteria.id)?.label ||  t(`segmentation.dashboard.sections.weasse_segmentation.widgets.${criteria.id}.label`),
        canUseForSegmentation: possibleCriteria?.canUseForSegmentation || true,
        categories: possibleCriteria?.categories || [],
        filterLabel: possibleCriterias?.find(possibleCrit => possibleCrit.id === criteria.filterId)?.label || 'unknown',
        displayInfo: {
          displayType: displayInfo,
          valueType: 'number' as const
        }
      })) || []
  ), [props.criterias, props.calculs, segmentationData, possibleCriteria, displayInfo]);

  useEffect(() => {
    getSegmentationData();
    getCriterias();
  }, [props.criteria, props.criterias]);

  useEffect(() => {
    configService.getAll()
      .then(res => setSegmentationConfigData(res.data));
  }, []);

  return (possibleCriteria && props.criteria ?
    <Dispatch
      criteria={{
        possibleDisplayTypes: possibleCriteria?.possibleDisplayTypes || [],
        on: possibleCriteria?.on || 'person',
        valueType: possibleCriteria?.valueType || 'number',
        values: segmentationData?.segmentationCriterias[props.criteria.id].values,
        isMultipleValuesOrRangesEnabled: true,
        id: props.criteria.id || 'unknown',
        filterId: props.criteria.filterId || 'unknown',
        label: props.criteria.label,
        canUseForSegmentation: possibleCriteria?.canUseForSegmentation || true,
        categories: possibleCriteria?.categories || [],
        filterLabel: possibleCriteria?.label || 'unknown',
        displayInfo: {
          displayType: displayInfo,
          valueType: 'number'
        }
      }}
      filters={[]}
      addFilter={filter => props.callSegmentation(
        'deecPerson',
        'persons',
        'person1_link_person2',
        [props.criteria?.filterId || ''],
        [filter.values?.join(',') || '']
      )}
      removeFilter={() => undefined}
      height={getBaseChartHeight(height)}
      handleChangeDisplayType={dt => setDisplayInfo(dt)}
      config={segmentationConfigData}
    /> : props.criterias ?
      <MultipleDispatch
        criterias={criterias}
        displayType={displayInfo}
        filters={[]}
        addFilters={filters => props.callSegmentation(
          'deecPerson',
          'persons',
          'person1_link_person2',
          filters.map(filter => filter.id),
          filters.map(filter => filter.values?.join(',') || '').join(';')
        )}
        removeFilter={() => undefined}
        height={getBaseChartHeight(height)}
        handleChangeDisplayType={dt => setDisplayInfo(dt)}
        config={segmentationConfigData}
        label={props.displayInfo?.label}
      /> : <></>
  );
}

export default PortfolioDashboardWidgetSegmentation;
